import Head from 'next/head';
import { setupIonicReact } from '@ionic/react';
import { AppProps } from 'next/app';
import { StatusBar } from '@capacitor/status-bar';

import 'tailwindcss/tailwind.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import '../styles/globals.css';
import React from 'react';
import { Capacitor } from '@capacitor/core';

setupIonicReact({});

interface CustomPageProps {}

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>) {
  async function hideStatusBar() {
    if (Capacitor.getPlatform() === 'web') return;
    StatusBar.hide();
  }

  React.useEffect(() => {
    hideStatusBar();
  });
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover,  user-scalable=0, maximum-scale=1.0"></meta>
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
